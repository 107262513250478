import { Vue, Component, Watch } from 'vue-property-decorator'
import { Container } from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { mapState } from 'vuex'
@Component({
  name: 'GtrCompanyGlobalAttendeeSearch',
  computed: {
    ...mapState('company', ['company', 'attendee_global_search'])
  }
})
export default class GtrCompanyGlobalAttendeeSearch extends Vue {
  attendee_global_search!: Record<string, any>;

  data () {
    return {
      loading: false,
      email: null,
      searchResults: null,
      participantUuid: null
    }
  }

  @Watch('attendee_global_search')
  onAttendeeGlobalSearchChange () {
    this.$data.searchResults = this.attendee_global_search
    this.$data.loading = false
    this.$store.dispatch('common/hideLoader')
  }

  async search () {
    try {
      this.$store.dispatch('common/showLoader', { value: true })
      this.$data.loading = true
      const payload = {
        criteria: {
          email: this.$data.email.toLowerCase()
        }
      }
      await this.$store.dispatch('company/saveAttendeeGlobalSearch', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  getParticipantUuid (i: number) {
    return this.$data.searchResults?.[i]?.participant_data.uuid ?? ''
  }
}
